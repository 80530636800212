import React from "react";
import "./Privacy.css";

const Privacy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>

      <h2>1. Information We Collect</h2>
      <p>We may collect and process the following types of information:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Name, email address, phone
          number, company name, job title, and other contact details.
        </li>
        <li>
          <strong>Technical Information:</strong> IP address, browser type and
          version, time zone setting, browser plug-in types and versions,
          operating system, and platform.
        </li>
        <li>
          <strong>Usage Information:</strong> Information about how you use our
          website, products, and services.
        </li>
        <li>
          <strong>Communication Information:</strong> Records of your
          correspondence with us, including support requests and feedback.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li>To provide, operate, and maintain our services.</li>
        <li>To improve, personalize, and expand our services.</li>
        <li>To understand and analyze how you use our services.</li>
        <li>To develop new products, services, features, and functionality.</li>
        <li>
          To communicate with you, including customer service, updates, and
          marketing.
        </li>
        <li>To process your transactions and manage your orders.</li>
        <li>To comply with legal obligations and resolve disputes.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>
        We may share your information with third parties in the following
        situations:
      </p>
      <ul>
        <li>
          With service providers and partners who assist us in delivering our
          services.
        </li>
        <li>
          With legal authorities if required by law or to protect our rights.
        </li>
        <li>
          In connection with a business transfer, such as a merger, acquisition,
          or asset sale.
        </li>
      </ul>

      <h2>4. Security of Your Information</h2>
      <p>
        We implement appropriate technical and organizational measures to
        protect your personal information from unauthorized access, use,
        disclosure, alteration, or destruction. However, no method of
        transmission over the internet or electronic storage is completely
        secure.
      </p>

      <h2>5. Your Rights and Choices</h2>
      <p>You have the following rights regarding your personal information:</p>
      <ul>
        <li>
          <strong>Access and Update:</strong> You can access and update your
          personal information by contacting us.
        </li>
        <li>
          <strong>Delete:</strong> You can request the deletion of your personal
          information, subject to certain legal obligations.
        </li>
        <li>
          <strong>Object and Restrict:</strong> You can object to or restrict
          the processing of your personal information in certain circumstances.
        </li>
        <li>
          <strong>Withdraw Consent:</strong> If we are processing your
          information based on your consent, you can withdraw your consent at
          any time.
        </li>
      </ul>

      <h2>6. Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar tracking technologies to track the activity
        on our website and store certain information. You can manage your cookie
        preferences through your browser settings.
      </p>

      <h2>7. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites. We are not
        responsible for the privacy practices or content of these third-party
        sites.
      </p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on our website. Your
        continued use of our services after the changes take effect constitutes
        your acceptance of the revised Privacy Policy.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at:
      </p>
      <address>
        PJSoftTech Pvt. Ltd.
        <br />
        PjSoftTech Pvt Ltd, 203, 2nd floor, Mangalmurti Complex, behind ABIL
        Tower, hirabagh chowk, Tilak Road, Shrukravar Peth, Pune-411002, India
        <br />
        Pune, Maharashtra, India
        <br />
        Email:{" "}
        <a href="mailto:info@pjsofttech.com">info@pjsofttech.com</a>  
        <br />
        Phone: [+919923570901]
      </address>
    </div>
  );
};

export default Privacy;
