import React from "react";
import "./Data.css";

const Refund = () => {
  return (

    <>
    

    <div className="Data-container">
      <h1>Refund Policy</h1>

      <h2>1. Introduction: </h2>
      <p>At Pjsofttech, we strive to deliver high-quality software solutions that meet your business needs. If you are not satisfied with our services or products, we offer refunds under specific circumstances outlined in this policy. Please review the details carefully to understand your rights and obligations.
      </p>

      <h2>2. Eligibility for Refunds :</h2>
      <p>Refund requests may be considered under the following conditions:</p>

      <ul>
        <li>The request is made within [7 days] of the purchase or initial service agreement.
        </li>

        <li>
        The software solution provided does not perform as described or fails to meet the agreed-upon specifications.
        </li>

        <li>
        The client has made all reasonable efforts to resolve any issues with the software or service through our support team.
        </li>
      </ul>


      <h2>3. Non-Refundable Services:  </h2>

      <p>The following services are non-refundable:</p>

      <ul>

        <li>
        Custom software development, once the development phase has commenced and agreed-upon milestones have been delivered.
        </li>

        <li>
        Any consulting or advisory services that have already been rendered.
        </li>

        <li>
        Monthly/Yearly subscription fees for software-as-a-service (SaaS) products after the trial period has ended.
        </li>

        <li>
        Maintenance and support services already performed.
        </li>

      </ul>


      <h2>4. Refund Process</h2>

      <p>If eligible for a refund, please follow these steps:</p>

        <ul>
            <li>
            Contact our support team at [sales@pjsofttech] or call [9923570901] to initiate the refund process.
            </li>

            <li>
            Provide all relevant details, including proof of purchase, service agreement, and a description of the issues encountered.
            </li>

            <li>
            Our team will review your case within [3-5 business days] and determine if a refund is warranted based on the specific circumstances.
Upon approval, the refund will be processed within [7] business days and applied to your original method of payment.
            </li>
        </ul>


    <h2>5. Partial Refunds :</h2>

    <p>In cases where partial services have been delivered or milestones completed, a partial refund may be granted. The refund will reflect the portion of the services that have not yet been fulfilled, based on the contract or agreement.
    </p>
      

    <h3>Subscription-Based Services
    </h3>

    <p>For clients using subscription-based software:
    </p>

    <ul>
        <li>
        If a cancellation request is received after the billing cycle has started, the service will remain active for the remainder of the billing period, but no refund will be issued for that period.
        </li>

        <li>
        Clients who cancel during a trial period will not be charged, and no refund will be necessary.
        </li>

    </ul>

    <h3>Disputed Charges</h3>

    <p>If you believe that there is an error in billing or charges, please contact our billing department within [10] days of the charge. We will investigate and resolve any discrepancies.
    </p>

    <h3>Limitations
    </h3>

    <p>
    Refunds are not available for issues resulting from:
    </p>

    <ul>
        <li>
        Client’s failure to provide necessary inputs, data, or access required for the software or services to function.

        </li>

        <li>
        Third-party integrations or systems outside of our control.

        </li>

        <li>
        Any misuse or unauthorized modification of the software by the client.
        </li>
    </ul>



      <h2>4. Contact Us</h2>
      <p>
      If you have any questions or need assistance with a refund, feel free to Contact us:

      </p>
      <address>
        PJSoftTech Pvt. Ltd.
        <br />
        PjSoftTech Pvt Ltd, 203, 2nd floor, Mangalmurti Complex, behind ABIL
        Tower, hirabagh chowk, Tilak Road, Shrukravar Peth, Pune-411002, India
        <br />
        Pune, Maharashtra, India
        <br />
        Email:{" "}
        <a href="mailto:sales@pjsofttech.com ">sales@pjsofttech.com </a>
        <br />
        Phone: [+919923570901]
      </address>
    </div>

    </>

  );
};

export default Refund;