import React from 'react';
import './Copyright.css';

const Copyright = () => {
  return (
    <div className="copyright-container">
      <h2>Copyright Policy</h2>
      <p>
        PJSofttech Pvt. Ltd. ("PJSofttech") is a leading software company established in 2020, dedicated to delivering cutting-edge software solutions that empower businesses and individuals to thrive in the digital era.
      </p>

      <h3>Ownership of Content</h3>
      <p>
        All content on the PJSofttech website and applications, including text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of PJSofttech, its affiliates, or its content suppliers. This content is protected by relevant copyright, authors' rights, and database right laws.
      </p>

      <h3>Use of Content</h3>
      <ul>
        <li><strong>Personal, Non-Commercial Use:</strong> Users may access and use the content on the PJSofttech website and applications for personal, non-commercial purposes only.</li>
        <li><strong>Prohibited Uses:</strong> Users may not systematically extract or re-utilize parts of the contents of the PJSofttech website and applications without PJSofttech's express written consent, except for personal, non-commercial use, and only if the source of the material is acknowledged.</li>
        <li><strong>Software and Databases:</strong> All software used on the PJSofttech website and applications is the property of PJSofttech, its affiliates, or its software suppliers and is protected by relevant copyright and authors' rights laws. Users may not modify, adapt, translate, reverse engineer, decompile, or disassemble any software or database associated with PJSofttech without PJSofttech's express written consent.</li>
      </ul>

      <h3>Copyright Infringement</h3>
      <p>
        PJSofttech respects the intellectual property rights of others. If you believe that any content on the PJSofttech website or applications infringes your copyright, please follow our Notification of Copyright Infringement process as outlined below:
      </p>
      <ul>
        <li>Provide a statement specifying the content on the PJSofttech website or application that you believe infringes your copyright.</li>
        <li>Include the title and description of the content, along with the full URL where it is available.</li>
        <li>Describe the copyrighted work that you believe has been infringed and provide a link if available.</li>
        <li>Specify the country in which your copyright applies.</li>
        <li>Explain how the content on the PJSofttech website or application infringes your copyright.</li>
        <li>Provide your contact information, including email and postal address, along with a phone number.</li>
        <li>Include a statement that you have a good-faith belief that the disputed use of the copyrighted work is not authorized by the copyright owner or permitted by law.</li>
        <li>Affix your electronic or physical signature.</li>
      </ul>

      <p>
        Please send your copyright infringement notice to:
        <br />
        By mail: <a href="mailto:info@pjsofttech.com">info@pjsofttech.com</a>
        <br />
        Address: PjSoftTech Pvt Ltd, 203,
2nd floor, Mangalmurti Complex,
behind ABIL Tower,
hirabagh chowk, Tilak Road,
Shrukravar Peth, Pune-411002, India
      </p>

      <p>
        PJSofttech reserves the right to update this Copyright Policy at any time. Changes will be effective immediately upon posting on the PJSofttech website or applications. Users are encouraged to review this policy periodically to stay informed about how PJSofttech protects its intellectual property rights.
      </p>
    </div>
  );
};

export default Copyright;
