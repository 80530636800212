import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logoPj.png";
import styles from "./Footer.module.css";
import Telegram from "../images/Telegram11.png";
import Youtube from"../images/youtube11.svg"
import Facebook from"../images/facebook11.svg"
import Linkedin from"../images/indeed11.svg"
import Instagram from"../images/instagram11.svg"
import Whatsapp from"../images/Whatsapp11.png"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faLinkedin,
//   faFacebook,
//   faYoutube,
//   faWhatsapp,
//   faTelegram,
//   faInstagram
// } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <>
      <footer className={styles.footer}>
  <div className={styles["container-fluid"]}>
    <div className={styles["container-top"]}>
      <div className={styles["footer-top"]}>
        {/* Uncomment this section if you want to display the logo */}
        {/* <div className={styles["footer-brand"]}>
          <img src={logo} className={styles["footer-logo"]} alt="Company Logo" />
          <span className={styles.span}>PJSoftTech</span>
        </div> */}
      </div>

      <div className={styles["container-bottom"]}>
        <div className={styles["footer-bottom"]}>

        <div className={styles["footer-services"]}>
  <div className={styles.services}>
    <h4>
      <span className={styles.span1}>Our Services</span>
    </h4>
  </div>
  <hr style={{ color: "white", width: "100%" }} />

  <div className={styles["services-grid"]}>
    {/* First column */}
    <div className={styles["service-col"]}>
      <ul>
        <li>
          <Link to="/student-information-system" style={{ color: "white", textDecoration: "none" }}>
            Student Management System
          </Link>
        </li>
        <li>
          <Link to="/online-admission-system" style={{ color: "white", textDecoration: "none" }}>
            Online Admission System
          </Link>
        </li>
        <li>
          <Link to="/fees-management-system" style={{ color: "white", textDecoration: "none" }}>
            Fees Management System
          </Link>
        </li>
        <li>
          <Link to="/online-examination-system" style={{ color: "white", textDecoration: "none" }}>
            Online Examination System
          </Link>
        </li>
        <li>
          <Link to="/attendance-management-system" style={{ color: "white", textDecoration: "none" }}>
            Higher Management System
          </Link>
        </li>
        <li>
          <Link to="/higher-education-system" style={{ color: "white", textDecoration: "none" }}>
            Attendance Education System
          </Link>
        </li>
      </ul>
    </div>

    {/* Second column */}
    <div className={styles["service-col"]}>
      <ul>
        <li>
          <Link to="/income-expenses-management-system" style={{ color: "white", textDecoration: "none" }}>
            Enquiry Management System
          </Link>
        </li>
        <li>
          <Link to="/institute-management-software" style={{ color: "white", textDecoration: "none" }}>
            Institute Management Software
          </Link>
        </li>
        <li>
          <Link to="/college-management-software" style={{ color: "white", textDecoration: "none" }}>
            College Management Software
          </Link>
        </li>
        <li>
          <Link to="/school-management-software" style={{ color: "white", textDecoration: "none" }}>
            School Management Software
          </Link>
        </li>
        <li>
          <Link to="/employee-management-system" style={{ color: "white", textDecoration: "none" }}>
            Employee Management System
          </Link>
        </li>
        <li>
          <Link to="/Income-Expenses-Management-System" style={{ color: "white", textDecoration: "none" }}>
            Income & Expenses Management Software
          </Link>
        </li>
      </ul>
    </div>
  </div>
</div>


<div className={styles["footer-services1"]}>
  <div className={styles.services2}>
    <h4><span className={styles.span2}>Links</span></h4>
  </div>
  <hr style={{ color: "white", width: "100%" }} />
  <div className={styles["services-grid"]}>
    <div className={styles["service-col"]}>
      <ul>
        <li>
          <Link to="/privacy-policy" style={{ color: "white", textDecoration: "none" }}>
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link to="/Organization" style={{ color: "white", textDecoration: "none" }}>
            Organization
          </Link>
        </li>
        <li>
          <Link to="/Terms-and-Conditions" style={{ color: "white", textDecoration: "none" }}>
            Terms and Conditions
          </Link>
        </li>
        <li>
          <Link to="/Copyright-Policy" style={{ color: "white", textDecoration: "none" }}>
            Copyright Policy
          </Link>
        </li>
        <li>
          <Link to="/Data-Protection-Addendum" style={{ color: "white", textDecoration: "none" }}>
            Data Protection Addendum
          </Link>
        </li>
        <li>
          <Link to="/Refund-Policy" style={{ color: "white", textDecoration: "none" }}>
            Refund Policy
          </Link>
        </li>
      </ul>
    </div>
  </div>
</div>

<div className={styles["footer-services3"]}>
  <div className={styles.services4}>
    <h4><span className={styles.span3}>Contact Us</span></h4>
  </div>
  <hr style={{ color: "white", width: "70%" }} />
  <div className={styles["services-grid"]}>
    <div className={styles["service-col"]}>
      {/* Location Section */}
      <div className={styles.contactItem}>
        <div>
          <a
            href="https://www.google.com/maps?q=PjSoftTech+pvt+ltd,+Lokmanya+Bal+Gangadhar+Tilak+Rd,+Sadashiv+Peth,+Pune,+Maharashtra+411030"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white", textDecoration: "none" }}
            className={styles.address}
          >
            <h4 className="fw-bold">PjSoftTech Pvt Ltd,</h4>
            203, 2nd floor, Mangalmurti <br />Complex, behind ABIL Tower, Hirabagh Chowk, Tilak Road, Shrukravar Peth, Pune-411002, India
          </a>
        </div>
      </div>

      {/* Email Section */}
      <div className={styles.contactItem}>
        <div className="text-start">
          <a href="mailto:pjsofttech@gmail.com" style={{ color: "white", textDecoration: "none" }}>
            info@pjsofttech.com
          </a>
        </div>
      </div>

      {/* Phone Section */}
      <div className={styles.contactItem}>
        <div>
          <a href="tel:+919923570901" style={{ color: "white", textDecoration: "none" }}>
            (+91) 99235 70901
          </a>
        </div>
      </div>
    </div>
  </div>
</div>





        </div>
      </div>


          


    </div>


          
          

  </div>

  <div className={styles["container-fluid1"]}>
  <div className={styles["container"]}>
    <div className=" d-flex justify-content-between align-items-between flex-column flex-lg-row pt-5">
      
      {/* Logo Section */}
      <div className="s1 d-flex align-items-center mb-3 mb-lg-0 pt-2">
        <img src="https://www.pjsofttech.com/logoPj.png" width="70px" alt="Logo" />
        <h4 className="ms-2 mt-1 fw-bold text-white fs-4">PJSOFTTECH</h4>
      </div>

      <div className={`${styles["footer-social-icons"]} mb-3 mb-lg-0 gap-2`}>


  {/* indeed */}
      <a href="https://www.linkedin.com/company/pjsofttech/" target="_blank" style={{ margin: '0 18px' }}>
    <img
        src={Linkedin}
        className="iconimg"
        style={{ width: '30px', height: '30px' }}
        alt="indeed"
    />
</a>


{/* facebook */}
<a href="https://www.facebook.com/people/PJSoftTech/61555475266348/" target="_blank" style={{ margin: '0 18px' }}>
    <img
        src={Facebook}
        className="iconimg"
        style={{ width: '30px', height: '30px' }}
        alt="facebook"
    />
</a>



{/* youtube */}
<a href="https://www.youtube.com/@PJsofttech" target="_blank" style={{ margin: '0 18px' }}>
    <img
        src={Youtube}
        className="iconimg"
        style={{ width: '30px', height: '30px' }}
        alt="youtube"
    />
</a>


{/* Whatsapp */}
<a href="https://wa.me/9923570901" target="_blank" style={{ margin: '0 18px' }}>
    <img
        src={Whatsapp}
        className="iconimg"
        style={{ width: '30px', height: '30px' }}
        alt="Whatsapp"
    />
</a>


{/* Telegram */}
<a href="https://t.me/pjsofttech" target="_blank" style={{ margin: '0 18px' }}>
    <img
        src={Telegram}
        className="iconimg"
        style={{ width: '30px', height: '30px' }}
        alt="Telegram"
    />
</a>


{/* instagram */}
<a href="https://www.instagram.com/pjsofttech/profilecard/?igsh=MWJua3BqbGVvMmY2NA==" target="_blank" style={{ margin: '0 18px' }}>
    <img
        src={Instagram}
        className="iconimg"
        style={{ width: '30px', height: '30px' }}
        alt="instagram"
    />
</a>


    
</div>



      {/* Images Section */}
      <div className="d-flex images justify-content-center justify-content-lg-start pt-3">
        <a href="https://play.google.com/store/apps/details?id=com.ooacademy.pune&hl=en_IN" target="_blank" rel="noopener noreferrer">
          <img src="https://proctur.com/assets/img/playstore.png" width="auto" height="40px" alt="Playstore 1" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.ooacademy.pune&hl=en_IN" target="_blank" rel="noopener noreferrer">
          <img src="https://proctur.com/assets/img/applestore.png" width="auto" height="40px" alt="Playstore 2" className="ms-3" />
        </a>
      </div>
      
    </div>
  </div>
</div>


</footer>





      <div className={styles["footer-copyright-container"]}>
        <p className={styles["footer-copyright"]}>
          &copy; 2024 PJSoftTech. All rights reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
