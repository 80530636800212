import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MyNavbar from './Navbar';
import Footer from './components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css'; // Importing the global CSS file
import ScrollToTop from './components/ScrollToTop';
import ScrollToTopButton from './components/ScrollToTopButton';
import Privacy from './components/Footer/Privacy';
import Terms from './components/Footer/Terms';
import Copyright from './components/Footer/Copyright';
import Data from './components/Footer/Data';
import Refund from './components/Footer/Refund';
import EmployeeManagementSystem from './components/Services/EmployeeManagementSystem';

const Home = lazy(() => import('./components/Home/Home'));
const StudentInformationSystem = lazy(() => import('./components/Services/StudentInformationSystem'));
const OnlineAdmissionSystem = lazy(() => import('./components/Services/OnlineAdmissionSystem'));
const FeesManagementSystem = lazy(() => import('./components/Services/FeesManagementSystem'));
const OnlineExaminationSystem = lazy(() => import('./components/Services/OnlineExaminationSystem'));
const AttendanceManagementSystem = lazy(() => import('./components/Services/AttendanceManagementSystem'));
const HigherEducationSystem = lazy(() => import('./components/Services/HigherEducationSystem'));
const IncomeExpensesManagementSystem = lazy(() => import('./components/Services/IncomeExpensesManagementSystem'));
const InstituteManagementSoftware = lazy(() => import('./components/Services/InstituteManagementSoftware'));
const CollegeManagementSoftware = lazy(() => import('./components/Services/CollegeManagementSoftware'));
const SchoolManagementSoftware = lazy(() => import('./components/Services/SchoolManagementSoftware'));
const EnquiryManagementSystem = lazy(() => import('./components/Services/EnquiryManagementSystem'));
const Solutions = lazy(() => import('./components/Solutions/Solutions'));
const Blogs = lazy(() => import('./components/Blogs/Blogs'));
const Team = lazy(() => import('./components/Team/Team'));
const Plan = lazy(() => import('./components/Plan/Plan'));
const Login = lazy(() => import('./components/Login/Login'));
const Organization = lazy(() => import('./components/Organization/Organization'));
const About = lazy(() => import('./components/About/About'));
const ContactUs = lazy(() => import('./components/ContactUs/ContactUs'));
const Become = lazy(() => import('./components/Become/Become'));
const ContactUs2 = lazy(() => import('./components/ContactUsSec/ContactUs_2'));


// Uncomment the NotFound route if you create the NotFound component

function App() {
  return (
    <Router>
      <ScrollToTop />
      <MyNavbar />
      <div style={{ minHeight: 'calc(100vh - 100px)' }}> {/* Adjusts for footer height */}
        <Suspense fallback={<div>Loading...</div>}> {/* You can replace this with a spinner */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/student-information-system" element={<StudentInformationSystem />} />
            <Route path="/online-admission-system" element={<OnlineAdmissionSystem />} />
            <Route path="/fees-management-system" element={<FeesManagementSystem />} />
            <Route path="/online-examination-system" element={<OnlineExaminationSystem />} />
            <Route path="/attendance-management-system" element={<AttendanceManagementSystem />} />
            <Route path="/higher-education-system" element={<HigherEducationSystem />} />
            <Route path="/income-expenses-management-system" element={<IncomeExpensesManagementSystem />} />
            <Route path="/institute-management-software" element={<InstituteManagementSoftware />} />
            <Route path="/college-management-software" element={<CollegeManagementSoftware />} />
            <Route path="/school-management-software" element={<SchoolManagementSoftware />} />
            <Route path="/employee-management-system" element={<EmployeeManagementSystem />} />
            <Route path="/enquiry-management-software" element={<EnquiryManagementSystem />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/organization" element={<Organization />} />
            <Route path="/plan" element={<Plan />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/copyright-policy" element={<Copyright />} />
            <Route path="/data" element={<Data />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/become" element={<Become />} />
            <Route path="/contact-us2" element={<ContactUs2 />} /> {/* Fixed the route casing */}
            {/* Uncomment the following line for a NotFound route if you implement the component */}
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Suspense>
      </div>
      <Footer />
      <ScrollToTopButton />
    </Router>
  );
}

export default App;
