import React from "react";
import "./Data.css";

const Data = () => {
  return (
    <div className="Data-container">
      <h1>Data Protection Addendum</h1>

      <h2>1. Data Information: </h2>
      <p>Our company does not store any data generated by us about you. We only keep information about your institute/school/firm/company which is suitable for us to provide you timely service, except the information you provide to us while registering Process KYC. We do not keep any other information except.
      </p>

      <h2>2. OTP and Password Protection. </h2>
      <p>Our company or our employees or technical team will never ask you for OTP or Username/Password. Dont give OTP or Username/Password to our company or company employee or technical team.</p>


      <h2>3. Data Protection:  </h2>

      <p>If your software/services plan is valid then you will have to renew the plan within 90 days after the last day. Otherwise, after the 90 days (3 months) it will go to Grace Paried and after that, it will remain safe with us for 270 days (12 months), after the completion of 365 days your data, you will not be able to ask us for it. Please note that. You should save your data within 365 days by downloading it in an organized manner. Otherwise, we will not commit to giving it to you after 365 days.
      </p>

      <p>If you do not like our service, you can stop it at any time. But you want want to access your data and for that you will have to pay data maintenance fee. Data maintenance fee will be as per your plan. 
      </p>

      <p>
      Data maintenance fee for basic plan is Rs 2000 per year, for premium plan it is Rs 4000 per year and The data maintenance fee for business plan is Rs 10000 per year only and you will not be able to add any entry in it. This fee is only so that you can view/sort and filter the data and download it.
      </p>

      <p>In future, we will be updating our data maintenance cost/plans from time to time. For this, please always visit our page. 
      </p>




      

      <h2>4. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Data Protection or our
        data practices, please contact us at:
      </p>
      <address>
        PJSoftTech Pvt. Ltd.
        <br />
        PjSoftTech Pvt Ltd, 203, 2nd floor, Mangalmurti Complex, behind ABIL
        Tower, hirabagh chowk, Tilak Road, Shrukravar Peth, Pune-411002, India
        <br />
        Pune, Maharashtra, India
        <br />
        Email:{" "}
        <a href="mailto:sales@pjsofttech.com ">sales@pjsofttech.com </a>
        <br />
        Phone: [+919923570901]
      </address>
    </div>
  );
};

export default Data;
