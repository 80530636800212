import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import styles from './EmployeeManagementSystem.module.css';
import image from '../images/Employee management system new.png'

const EmployeeManagementSystem = () => {
  const blogs = [
    {
      id: 1,
      title: "Real-Time Financial Tracking",
      image: "https://static.thenounproject.com/png/1326808-200.png",
      content:
        "Stay on top of your finances with instant updates on your income and expenses. Real-time tracking allows you to monitor every transaction as it happens, ensuring accurate financial insights and enabling timely decision-making for smarter financial management.",
    },
    {
      id: 2,
      title: "Automated Expense Categorization",
      image: "https://cdn-icons-png.freepik.com/512/8161/8161813.png",
      content:
        "Simplify your financial management with automated expense categorization. Our system automatically sorts and classifies your expenses into predefined categories, making it easier to track spending patterns, analyze budgets, and identify areas for saving.",
    },
    {
      id: 3,
      title: "Custom Budget Creation",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWfEia_LIXvLVy-96JHEqdbFMnCHFvJ-6wvg&s",
      content:
        "Take control of your financial journey with our custom budget creation feature. Design personalized budgets that align with your unique financial goals, allowing for flexible adjustments as your circumstances change. Easily allocate funds to different categories and monitor your progress to ensure you stay on track.",
    },
    {
      id: 4,
      title: "Expense Alerts and Notifications",
      image: "https://static.vecteezy.com/system/resources/thumbnails/007/890/472/small/workflow-icon-on-white-background-workflow-sign-flat-style-vector.jpg",
      content:
        "Never miss a beat with our expense alerts and notifications. Receive real-time updates when you approach budget limits or when unusual spending patterns are detected. This proactive feature helps you stay informed about your financial health, enabling you to make timely adjustments and avoid overspending",
    },
    {
      id: 5,
      title: "Multi-User Access with Permissions",
      image: "https://t4.ftcdn.net/jpg/04/09/67/55/360_F_409675503_SU9GSAeToH8MIgrCzFVEp7pmoysPX7oq.jpg",
      content:
        "Collaborate seamlessly with our multi-user access feature, allowing multiple individuals to manage finances within a single account. Customize user roles and permissions to ensure everyone has the right level of access, enhancing teamwork while maintaining data security. Perfect for families or businesses looking to streamline financial management together",
    },
    {
      id: 6,
      title: "Tax Preparation Support",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdLs5onzKyL393e24BnyzV_ifhPhz3iQvDLQ&s",
      content:
        "Simplify your tax season with our comprehensive tax preparation support. Our system organizes your income and expenses, providing detailed reports and insights that make tax filing a breeze. Stay compliant and maximize deductions by having all your financial data readily available, ensuring a stress-free tax experience.",
    },
    {
      id: 7,
      title: "24*7 Support",
      image: "https://cdn-icons-png.flaticon.com/512/5167/5167002.png",
      content:
        "Providing round-the-clock support is crucial for addressing the diverse needs of students, faculty, and staff. A 24*7 support system ensures that technical issues, queries, and concerns are promptly resolved, enhancing the overall experience and minimizing disruptions to the learning process.",
    },
    {
      id: 8,
      title: "Reports and Dashboard",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdPy68qamXA8KAQkvDR2mOn4YCbWW3kOfIyA&s",
      content:
        "Effective decision-making relies on accurate and timely information. Implementing comprehensive reports and dashboards allows educational institutions to visualize data, track key performance indicators, and generate insights that drive strategic planning and operational improvements.",
    },
    {
      id: 9,
      title: "Next Generation Software",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT003mM6ayyH3qHORxwe14gGfRaNHLS-xMXug&s",
      content:
        "Adopting next-generation software solutions is essential for staying ahead in the rapidly evolving educational landscape. These advanced systems offer innovative features, enhanced user experience, and greater flexibility, enabling institutions.",
    },
  ];

  return (
    <>
      <div>
      <div className="row" style={{ position: "relative" }}>
    <div className="col">
        <img
        className={styles.Img2}
            src="https://educloud.app/lms/src/attendance/hero2.png"
            alt="student_photo"
            // className={styles.simg}
        />
    </div>
    <div className="col div1 pt-5">
        <h1 className={styles.h1}>
            <div className="h1-content ">
                <span className={styles.span}>C</span>omprehensive< br/>
                <span className={styles.span}>E</span>mployee<br />
                <span className={styles.span}>M</span>anagement <br />
                <span className={styles.span}>S</span>ystem<br />
                {/* Comprehensive Faculty Management System */}
            </div>
        </h1>
        <p className={styles.top_text}>
        A comprehensive employee management system is essential for effectively handling all aspects of faculty administration within educational institutions. This robust system streamlines the recruitment process by automating job postings, applicant tracking, and interview scheduling. Once candidates are hired, it facilitates a smooth onboarding process with digital forms, orientation materials, and training resources.
        </p>
    </div>
</div>

        <div className="container-fluid p-5 text-center mt-3">
          <h2 className="fs-1">
          <span className={styles.span}> Efficient </span> Efficient Source Tracking for Income & Expense Management

          </h2>
          <p className="mt-4">
          A Source Tracking feature in an Income & Expense Management System acts as a streamlined hub for organizing and tracking the origins of financial transactions. This functionality ensures accurate tracking of income sources and expenses, improving financial transparency and decision-making for institutions and businesses alike.
          </p>
        </div>
        <div>
        <h2 className="text-center mt-5 key-features"><span className={styles.span}>Key</span> Features</h2>
        </div>
        
        <div className={styles.div2}>
          <Container className="mt-5">
            <Grid container spacing={3}>
              {blogs.map((blog) => (
                <Grid item key={blog.id} lg={4} md={6} className="mb-4">
                  <Card className="d-flex justify-content-center align-items-center bg-light">
                    <CardMedia
                      component="img"
                      image={blog.image}
                      style={{
                        width: "75px",
                        height: "75px",
                        objectFit: "cover",
                      }}
                    />
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        className="d-flex justify-content-start fw-bold align-items-center"
                      >
                        {blog.title}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {blog.content}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
              <img  className={styles.simg} src={image} alt="imag"></img>
        
        <div className={styles.container}>
        <div className={styles.div3}>
        <div className="row">
          <div className="col sub">
          <p>
          Real-Time Financial Tracking within an Income & Expense Management System empowers businesses and institutions to monitor their financial activity as it happens. This feature provides instant visibility into income and expenses, ensuring that every transaction is captured and updated in real-time.

              <br /> <br /> Administrators can see incoming payments and outgoing expenses as they occur, allowing for more accurate and timely decision-making. The system automatically syncs data from multiple sources, giving a comprehensive view of financial health at any given moment.

              <br />
              <br /> With real-time data, institutions can track cash flow, assess budget performance, and respond to financial trends immediately. This capability minimizes delays, enhances transparency, and provides a clear picture of the current financial situation.
              <br />
              <br />
              Real-time financial tracking helps institutions stay agile, making it easier to adjust budgets, forecast future financial needs, and ensure that all financial activities align with strategic goals.
            </p>
          </div>
          <div className="col">
          <h1 className="pt-4 pb-2 text-center"><span className={styles.span}>Real-Time</span> Financial Tracking </h1>
          <img className={styles.Img1} src="https://img.freepik.com/premium-photo/illustration-time-tracking-data-analysis-reports_1314467-212388.jpg?w=740" alt="img"></img>
          </div>
        </div>
      </div>
      </div>


      <div className={styles.div4}>

        <div className="row">
        <div className="col">
          <h1 className="pt-4 pb-2 text-center"><span className={styles.span}>Expense</span> Alerts and Notifications </h1>
          <img className={styles.Img1} src="https://img.freepik.com/premium-vector/smartphone-with-envelope_188544-4023.jpg?w=740" alt="img"></img>
          </div>
          <div className="col sub">
          <p>
          Expense Alerts and Notifications within an Income & Expense Management System provide businesses and institutions with timely updates on their financial activities. This feature ensures that administrators are immediately informed of any significant or unusual expenses, helping them stay in control of their budget and cash flow.

              <br />
              <br />With customizable alert settings, users can define thresholds for expenses, ensuring that notifications are sent when spending reaches a certain limit or when a specific type of transaction occurs. These real-time alerts help prevent overspending, detect errors or discrepancies, and allow for prompt corrective action.

              <br /> <br />
              The system can deliver alerts via email, SMS, or in-app notifications, ensuring that administrators are always aware of critical financial activity, regardless of their location. This level of transparency enhances financial oversight and empowers institutions to make data-driven decisions more quickly.

              <br /> <br /> 
              Expense Alerts and Notifications not only improve financial control but also contribute to better compliance and budget adherence, reducing the risk of financial mismanagement
            </p>
          </div>
        </div>
      </div>


      <div className={styles.div5}>
        <div className="row">
          <div className="col sub">
          <p>
          Multi-User Access with Permissions in an Income & Expense Management System allows businesses and institutions to delegate financial tasks securely while maintaining control over sensitive data. This feature enables multiple users, such as administrators, accountants, or department heads, to access the system based on predefined permission levels.

              <br /><br/>Administrators can customize permissions, granting access to specific features like transaction entry, report generation, or budget management, while restricting access to confidential financial information. This ensures that each user can only perform actions relevant to their role, reducing the risk of errors or unauthorized access.

              <br /> <br /> The system also tracks user activity, providing an audit trail that shows who accessed or modified financial data. This feature enhances accountability and transparency, enabling businesses to monitor usage and detect potential security issues.

              <br /> <br /> Multi-User Access with Permissions streamlines collaboration within the organization, allowing different teams to manage finances more efficiently while ensuring that sensitive information remains secure. This capability contributes to improved financial oversight, data integrity, and operational efficiency.

              </p>
          </div>
          <div className="col">
          <h1 className="pt-4 pb-2 text-center"><span className={styles.span}>Multi-User </span> Access with Permissions</h1>
          <img className={styles.Img1} src="https://img.freepik.com/premium-photo/man-sits-front-computer-with-diagram-financial-data_950428-26867.jpg?w=740" alt="img"></img>
          </div>
        </div>
      </div>



      </div>
    </>
  );
};

export default EmployeeManagementSystem;