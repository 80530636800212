import React from 'react';
import './TermsAndConditions.css';

const Terms = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      
      <h2>1. Acceptance of Terms</h2>
      <p>By accessing and using our services, you accept and agree to be bound by the terms and provision of this agreement. In addition, when using our services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use our services.</p>

      <h2>2. Services Provided</h2>
      <p>PJSoftTech Pvt. Ltd. offers IT services and IT consulting, including but not limited to software development, system integration, and technical support.</p>

      <h2>3. User Responsibilities</h2>
      <p>As a user of our services, you agree to provide accurate and complete information when requested. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account.</p>

      <h2>4. Prohibited Activities</h2>
      <p>You agree not to engage in any of the following activities:</p>
      <ul>
        <li>Violating any applicable laws or regulations.</li>
        <li>Infringing on the intellectual property rights of others.</li>
        <li>Engaging in any fraudulent or deceptive practices.</li>
        <li>Introducing any viruses or other malicious code.</li>
        <li>Interfering with the operation of our services.</li>
      </ul>

      <h2>5. Limitation of Liability</h2>
      <p>PJSoftTech Pvt. Ltd. shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our services, including but not limited to damages for loss of profits, use, data, or other intangibles.</p>

      <h2>6. Termination</h2>
      <p>We reserve the right to terminate your access to our services without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users, us, or third parties, or for any other reason.</p>

      <h2>7. Changes to Terms</h2>
      <p>We reserve the right to modify these Terms and Conditions at any time. You should check this page regularly. Your continued use of our services after any changes constitutes your acceptance of the new Terms and Conditions.</p>

      <h2>8. Governing Law</h2>
      <p>These Terms and Conditions are governed by and construed in accordance with the laws of India. You agree to submit to the exclusive jurisdiction of the courts located in Pune, Maharashtra, for the resolution of any disputes.</p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Term and Conditions or our
        data practices, please contact us at:
      </p>
      <address>
        PJSoftTech Pvt. Ltd.
        <br />
        PjSoftTech Pvt Ltd, 203, 2nd floor, Mangalmurti Complex, behind ABIL
        Tower, hirabagh chowk, Tilak Road, Shrukravar Peth, Pune-411002, India
        <br />
        Pune, Maharashtra, India
        <br />
        Email:{" "}
        <a href="mailto:info@pjsofttech.com">info@pjsofttech.com</a>
        <br />
        Phone: [+919923570901]
      </address>
    </div>
  );
};

export default Terms;
